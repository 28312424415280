import React, {useState} from 'react';

export const HoroscopeGenerator = ({generator}) => {

    const [horoscope, setHoroscope] = useState("");
    const [sign, setSign] = useState("Aquarius");
    const [focus, setFocus] = useState("");
    return (
        <div>
           Sign: <select value={sign} onChange={(event) =>{
            setSign(event.target.value);
           }}name="sign" id="signSelector">
                <option value="Aquarius">Aquarius</option>
                <option value="Pisces">Pisces</option>
                <option value="Aries">Aries</option>
                <option value="Taurus">Taurus</option>
                <option value="Gemini">Gemini</option>
                <option value="Cancer">Cancer</option>
                <option value="Leo">Leo</option>
                <option value="Virgo">Virgo</option>
                <option value="Libra">Libra</option>
                <option value="Scorpio">Scorpio</option>
                <option value="Sagittarius">Sagittarius</option>
                <option value="Capricorn">Capricorn</option>
            </select> 
            <div hidden={generator ==="Daily"}>
                Focus: <input onChange={(event) =>{
                    setFocus(event.target.value);
                }}name="focus"/>
            </div>
            <button onClick={() => {
                fetch(`/get${generator}Horoscope?sign=${sign}&focus=${focus}`).then(response => {
                    response.text().then(data => {
                        setHoroscope(data);
                    })
                })
            }}>Generate Horoscope</button>
            <p>{horoscope}</p>
            
        </div>
    )
}