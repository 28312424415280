import React, { useState} from 'react';
import { HoroscopeGenerator } from './HoroscopeGenerator';
export const HoroscopeSelector = () => {

    const [horoscopeGenerator, setHoroscopeGenerator] = useState("Daily");
    return (
        <div>
            <div id="horoscopeGenerator Selector">
                <button onClick={() => {
                    setHoroscopeGenerator("Daily")
                }}>Daily Horoscope</button>
                <button onClick={() => {
                    setHoroscopeGenerator("Focus")
                }}>Focus Horoscope</button>
            </div>
            <HoroscopeGenerator generator={horoscopeGenerator}/>
        </div>
    );
}