import './App.css';
import { HoroscopeSelector } from './components/HoroscopeSelector';
function App() {
  return (
    <div className="App">
      <HoroscopeSelector/>
    </div>
  );
}

export default App;
